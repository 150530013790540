/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-loss/hearing-aids-increase-happiness/'], ['en', 'https://www.hear.com/resources/hearing-loss/hearing-aids-increase-happiness/'], ['en-US', 'https://www.hear.com/resources/hearing-loss/hearing-aids-increase-happiness/'], ['en-CA', 'https://ca.hear.com/resources/hearing-loss/hearing-aids-increase-happiness/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "surveys-say-hearing-aids-increase-happiness",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#surveys-say-hearing-aids-increase-happiness",
    "aria-label": "surveys say hearing aids increase happiness permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Surveys Say: Hearing Aids Increase Happiness"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "I hope you’re already happy, but wouldn’t it be better to be happier?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Dr. Dan Gilbert has been studying the subject of happiness for over 20 years. He points out that our brains have evolved to be social. As a result, spending more time with people is vital to our happiness. Whether it’s with loved ones or work colleagues, having strong relationships makes us happy, satisfied, and healthy."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "negative-effects-of-untreated-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#negative-effects-of-untreated-hearing-loss",
    "aria-label": "negative effects of untreated hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Negative effects of untreated hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unfortunately, we know that hearing loss can make it harder for us to be social in everyday lives. Straining to hear what someone is saying can leave us exhausted, slow our thinking, and affect our memory. And studies show it can lead to increased risk of loneliness and depression because socializing can be more challenging."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A study by The National Council on the Aging (NCOA) debunks the myth that untreated hearing loss is harmless. They surveyed 2,300 adults over 50 who have hearing loss. The researchers found that people with untreated hearing loss are more likely to have depression, anxiety, and paranoia. They were also less likely to participate in organized social activities, compared to those who wear hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-importance-of-effortless-understanding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-importance-of-effortless-understanding",
    "aria-label": "the importance of effortless understanding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The importance of effortless understanding"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We know relationships thrive when we understand each other. But how many times have you “heard” someone speak but couldn’t really understand what they said? Or had to ask someone to repeat themselves? When it’s difficult to communicate with someone, relationships can suffer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thankfully, medical-grade hearing aids are proven to treat hearing loss and designed to increase speech understanding. When it’s easier to understand every word, we can focus our attention on our family, friends, and colleagues. With effortless hearing, social interactions are more enjoyable and more frequent, leading to increased happiness."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "proof-hearing-aids-make-us-happier",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#proof-hearing-aids-make-us-happier",
    "aria-label": "proof hearing aids make us happier permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Proof: hearing aids make us happier"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Since 2009, EuroTrak has surveyed over 175,000 people with hearing loss and hearing aids. Almost 50% of all hearing aid wearers report that their home and work relationships are “better” or “a lot better” since they started wearing hearing aids. They report a better sense of safety, independence, and memory. 91% report improvements at work, but it’s even more impressive to see wearers earning $44,000 more money throughout their remaining working years! Overall, 96% of people reported that hearing aids improved their quality of life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The NCOA study mentioned above also shows hearing aid users reported significant improvements in many areas of their lives. These areas ranger from their relationships to their independence to their social life to their sex life. But what’s most exciting is that in virtually every dimension, the families of hearing aid users are more likely to report improvements. So if you don’t want to do it for yourself, do it for your loved ones."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "experience-the-difference-youll-be-happy-you-did",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#experience-the-difference-youll-be-happy-you-did",
    "aria-label": "experience the difference youll be happy you did permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Experience the difference. You’ll be happy you did."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Looking at the survey results you can see the difference hearing aids have on people’s happiness and quality of life. Many of our customers tell us, “I wish I would have done this sooner”. The best way to experience the benefits for yourself is with a 30-day trial. If you haven’t scheduled your appointment yet, make sure to click the button below."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
